.custom-tab {
  position: relative;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  padding: 10px 14px !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.main-tab {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 4px;
  width: 185px;
}

.custom-tab.dark {
  border-color: #374151;
  background-color: #1f2937;
}

.custom-tab:hover {
  background-color: #f3f4f6;
}

.custom-tab.dark:hover {
  background-color: #4d5056;
}

.custom-tab[data-active] {
  z-index: 10;
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.custom-tab[data-active]:hover {
  background-color: #000000;
}

@media (max-width: 400px) {
  .rccs {
    width: 100% !important;
  }
  .rccs__card {
    width: 100% !important;
  }
}
