.flag-dropdown {
  height: 43px;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #e5e7eb !important;
  background-color: #f5f5f5 !important;
  border-right: 0 !important;
}

.react-tel-input .country-list {
  max-height: 100px;
}
.react-tel-input input {
  border-width: 1px !important;
  border-style: solid !important;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  border-color: #e5e7eb !important;
  height: 43px !important;
  background-color: #f5f5f5 !important;
}

.react-tel-input .selected-flag {
  width: 43px;
}
.react-tel-input input {
  width: 100% !important;
}
